import { SEO, SliceZone } from "@/components";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import get from "lodash/get";
import { Date, RichText } from "prismic-reactjs";
import React from "react";
import tw from "tailwind.macro";

export const query = graphql`
  query PrismicBlogPost($uid: String!, $lang: String!) {
    prismic {
      blog_post(uid: $uid, lang: $lang) {
        title
        author
        excerpt
        featured_image
        featured_imageSharp {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        body {
          ... on PRISMIC_Blog_postBodyText {
            type
            primary {
              text
            }
          }
          ... on PRISMIC_Blog_postBodyQuote {
            type
            primary {
              quote
            }
          }
        }
        _linkType
        _meta {
          uid
          firstPublicationDate
          lastPublicationDate
        }
      }
    }
  }
`;

export default ({ data, location }) => {
  const title = get(data, "prismic.blog_post.title") || [];
  const author = get(data, "prismic.blog_post.author");
  const excerpt = get(data, "prismic.blog_post.excerpt") || [];
  const sharpImage = get(data, "prismic.blog_post.featured_imageSharp.childImageSharp.fluid");
  const firstPublicationDate = get(data, "prismic.blog_post._meta.firstPublicationDate");
  const lastPublicationDate = get(data, "prismic.blog_post._meta.lastPublicationDate");
  const body = get(data, "prismic.blog_post.body") || [];

  return (
    <>
      <SEO
        title={title ? RichText.asText(title) : ""}
        desc={excerpt ? RichText.asText(excerpt) : ""}
        pathname={location.pathname}
        meta={{ firstPublicationDate, lastPublicationDate }}
        article
      />

      {sharpImage ? (
        <Img fluid={sharpImage} />
      ) : (
        <FallbackImg
          src={get(data, "prismic.blog_post.featured_image.url")}
          alt={get(data, "prismic.blog_post.featured_image.alt")}
        />
      )}

      <Main>
        <Title>{RichText.render(title)}</Title>
        <Publication>
          <p>
            {firstPublicationDate
              ? Date(firstPublicationDate).toUTCString().substring(0, 16)
              : null}
          </p>
          <p>By {author}</p>
        </Publication>

        <Content>
          <SliceZone allSlices={body} />
        </Content>
      </Main>
    </>
  );
};

const FallbackImg = styled.img`
  ${tw`object-cover h-64 w-full`}
`;

const Main = styled.div`
  ${tw`max-w-screen-md mx-auto mt-12`}
`;

const Title = styled.div`
  ${tw`mb-8`}
`;

const Publication = styled.div`
  ${tw`mb-8`}
`;

const Content = styled.div``;
